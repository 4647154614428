import { useEffect, useRef, useState } from 'react';
import useScreenSize from './useScreenSize';

const useHeaderHeight = () => {

  const { isSmallScreen } = useScreenSize();
  const headerRef = useRef(null);
  const [ height, setHeight ] = useState('100vh');
  const [ headerHeight, setHeaderHeight ] = useState('60px');

  const updateHeaderHeight = () => {
    if (headerRef.current) {
      const newHeaderHeight = headerRef.current.offsetHeight;
      setHeaderHeight(newHeaderHeight);
      setHeight(`calc(100vh - ${newHeaderHeight}px)`);
    }
  };

  useEffect(() => {
    updateHeaderHeight();

    window.addEventListener('resize', updateHeaderHeight);

    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, [isSmallScreen]);

  return { height, headerHeight, headerRef };
};

export default useHeaderHeight;
