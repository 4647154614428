import styled from 'styled-components';
import useScreenSize from '../../hooks/useScreenSize';
import saltmine from '../../img/saltmine.svg';


const Footer = () => {

    const { isSmallScreen } = useScreenSize();

    
    return (
        <FooterContainer isSmallScreen={isSmallScreen}>
            <FooterInner isSmallScreen={isSmallScreen}>
                <Info isSmallScreen={isSmallScreen}>
                    <ImgBox isSmallScreen={isSmallScreen}>
                        <img src={saltmine} alt="소금광산" className="block w-full h-full" />
                    </ImgBox>
                    <Company isSmallScreen={isSmallScreen}>
                        <Name isSmallScreen={isSmallScreen}>
                            <b>주식회사 소금광산</b>
                        </Name>
                        <div className={`
                            flex 
                            ${isSmallScreen ? 'flex-row' : 'flex-col'}
                            ${isSmallScreen ? 'justify-center' : 'justify-start'}
                            ${isSmallScreen ? 'gap-x-9' : ''}
                            ${isSmallScreen ? 'mb-9' : ''}
                            `}
                            >
                            <span><b>대표이사ㅣ</b>유창현</span>
                            <span><b>사업자등록번호ㅣ</b>873-86-01911</span>
                        </div>
                    </Company>
                    <Address>
                        <span><b>본사주소ㅣ</b>(12902) 경기도 하남시 미사강변한강로 177, 3층 하남스타트업캠퍼스 7호</span>
                        <span><b>연구소 주소ㅣ</b>(31145) 충남 천안시 서북구 천안천4길 32, 그린스타트업타운 501호</span>
                        <span className={`${isSmallScreen ? 'mt-9' : ''}`}><b>연락처ㅣ</b>070-7799-7410</span>
                    </Address>
                </Info>
                <Copyright isSmallScreen={isSmallScreen}>
                    <span>Ⓒ 2023. SALTMiNE, Inc. all rights reserved.</span>
                </Copyright>
            </FooterInner>
        </FooterContainer>
    );

}

export default Footer;


const FooterContainer = styled.footer`
    width: 100%;
    margin-top: ${({ isSmallScreen }) => isSmallScreen ? '120px' : '200px'};
    padding: ${({ isSmallScreen }) => isSmallScreen ? '89px 0' : '97px 0'};

    border-top-width: 1px;
`;

const FooterInner = styled.section`
    width: ${({ isSmallScreen }) => isSmallScreen ? '238px' : '845px'};
    margin: 0 auto;

    font-size: ${({ isSmallScreen }) => isSmallScreen ? '10.5px' : '14px'};
    text-align: ${({ isSmallScreen }) => isSmallScreen ? 'center' : 'left'};
    white-space: ${({ isSmallScreen }) => isSmallScreen ? 'wrap' : 'nowrap'};
`;

const Info = styled.div`
    display: flex;
    flex-direction: ${({ isSmallScreen }) => isSmallScreen ? 'column' : 'row'};
    column-gap: 40px;
`;

const ImgBox = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '97px' : '116px'};
    height: ${({ isSmallScreen }) => isSmallScreen ? '24px' : '35px'};
    
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: ${ ({isSmallScreen }) => isSmallScreen ? 'center' : 'flex-start'};
`;

const Company = styled.div`
    display: flex;
    flex-direction: column;

    margin-top: ${({ isSmallScreen }) => isSmallScreen ? '30px' : '0'};
    gap: ${({ isSmallScreen }) => isSmallScreen ? '15px' : '0'};
`;

const Name = styled.span`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '12px' : '14px'};
`;

const Address = styled.div`
    display: flex;
    flex-direction: column;
`;

const Copyright = styled.div`
    margin-top: 15px;
    margin-left: ${({ isSmallScreen }) => isSmallScreen ? '0' : '152px'};
`;