import styled from "styled-components";
import Required from "./Required";

const TextArea = ({ label, type, name, value, handleChange, placeholder, isSmallScreen, hasError }) => {

    return (
        <div className={`${isSmallScreen ? 'mt-24' : 'mt-40'}`}>
            <div>
                <Label isSmallScreen={isSmallScreen}>
                    <div>
                        <span>
                            { label }
                        </span>
                        <Required>*</Required>
                    </div>
                        <Input
                            type={type}
                            name={name}
                            placeholder={placeholder}
                            value={value}
                            onChange={handleChange}
                            isSmallScreen={isSmallScreen}
                            hasError={hasError}
                        />
                </Label>
            </div>
        </div>
    );

}

export default TextArea;


const Label = styled.label`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '16px' : '20px'};
    font-weight: ${({ isSmallScreen }) => isSmallScreen ? '700' : '600'};
    color: ${({ theme }) => theme.colors.gray4};
    line-height: 30px;

    display: flex;
    flex-direction: ${({ isSmallScreen }) => isSmallScreen ? 'column' : 'row'};
    gap: ${({ isSmallScreen }) => isSmallScreen ? '8px' : ''};
    justify-content: ${({ isSmallScreen }) => isSmallScreen ? '' : 'space-between'};

    input:focus + &,
    textarea:focus + & {
        color: ${({ theme }) => theme.colors.gray4};
    }
`;

const Input = styled.textarea`
    width: ${({ isSmallScreen }) => isSmallScreen ? '320px' : '580px'};
    height: 320px;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 16px;
    border: 1px solid ${({ theme, hasError }) => hasError ? 'red' : theme.colors.gray7};
    border-radius: 16px;

    f${({ isSmallScreen }) => isSmallScreen ? '16px' : '18px'};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray3};

    appearance: none;
    resize: none;
    outline: none;

    &:focus {
        border: 1px solid ${({ theme }) => theme.colors.gray7};
    }
`;