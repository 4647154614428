import styled from "styled-components";


const Content = ({ title, subCopy, color, isSmallScreen }) => {

    return (
        <div>
            <Title 
                dangerouslySetInnerHTML={{ __html: title }}
                color={color}
                isSmallScreen={isSmallScreen}
            />
            <SubCopy 
                dangerouslySetInnerHTML={{ __html: subCopy }}
                color={color}
                isSmallScreen={isSmallScreen}
            />
        </div>
    );

}

export default Content;


const Title = styled.h1`
    font-size: ${ ({isSmallScreen}) => isSmallScreen ? '20px' : '28px' };
    font-weight: 700;
    color: ${ ({color}) => color };
    text-align: center;
    line-height: 140%;

    margin-bottom: ${ ({isSmallScreen}) => isSmallScreen ? '8px' : '16px' };
`;

const SubCopy = styled.p`
    font-size: ${ ({isSmallScreen}) => isSmallScreen ? '14px' : '18px' };
    color: ${ ({color}) => color };
    text-align: center;
    line-height: 160%;
    letter-spacing: -0.27px;

    margin-bottom: ${ ({isSmallScreen}) => isSmallScreen ? '24px' : '60px' };
`;