import tokenPost220723 from '../img/pressRelease/2022_07_23.jpg';
import dailyEconomy230814 from '../img/pressRelease/2023_08_14.jpg';
import startup231031 from '../img/pressRelease/2023_10_31.jpg';
import dailyEconomy231116 from '../img/pressRelease/2023_11_16.jpg';
import newsfim231204 from '../img/pressRelease/2023_12_04_1.jpg';
import shinailbo231204 from '../img/pressRelease/2023_12_04_2.jpeg';
import hanamilbo231204 from '../img/pressRelease/2023_12_04_3.jpg';
import cbc231204 from '../img/pressRelease/2023_12_04_4.jpeg';
import ajunews231204 from '../img/pressRelease/2023_12_04_5.jpeg';
import thebigdata231215 from '../img/pressRelease/2023_12_15_1.jpeg';
import fntoday231215 from '../img/pressRelease/2023_12_15_2.png';
import globalepic240213 from '../img/pressRelease/2024_02_13.jpeg';
import ekn240320 from '../img/pressRelease/2024_03_20_1.png';
import moneyToday240320 from '../img/pressRelease/2024_03_20_2.avif';
import thebigdata240426 from '../img/pressRelease/2024_04_26 .jpeg';
import cstimes240710 from '../img/pressRelease/2024_07_10.jpg';
import sisunnews240718 from '../img/pressRelease/2024_07_18.jpg';


export const pressReleaseData = [

    {
        img : sisunnews240718,
        head : '주식회사 소금광산, 중기부 ‘팁스(TIPS)’ 선정',
        date : '2024.07.18',
        link : 'https://www.sisunnews.co.kr/news/articleView.html?idxno=213062'
    },
    {
        img : cstimes240710,
        head : '인생식당-소금광산, 고객 맞춤 서비스 위한 업무협약 체결',
        date : '2024.07.10',
        link : 'https://www.cstimes.com/news/articleView.html?idxno=601031'
    },
    {
        img : thebigdata240426,
        head : '위티-소금광산, ‘스몰데이터 기반 서비스 고도화를 위한’ 업무협약 체결',
        date : '2024.04.26',
        link : 'https://thebigdata.co.kr/view.php?ud=2024042609583023106cf2d78c68_23'
    },
    {
        img : moneyToday240320,
        head : '‘스몰데이터 특화 솔루션’ 소금광산, 신보 리틀펭귄 선정',
        date : '2024.03.20',
        link : 'https://news.mt.co.kr/mtview.php?no=2024032015260897261'
    },
    {
        img : ekn240320,
        head : '스몰데이터 솔루션 개발 기업 소금광산, 신보 ‘리틀펭귄 프로그램’ 선정',
        date : '2024.03.20',
        link : 'https://m.ekn.kr/view.php?key=20240320001306397'
    },
    {
        img : globalepic240213,
        head : '케이닥-소금광산, ‘K-메디컬 디지털화 및 글로벌 확장을 위한’ 업무협약 체결',
        date : '2024.02.13',
        link : 'https://www.globalepic.co.kr/view.php?ud=2024021311234794706cf2d78c68_29'
    },
    {
        img : fntoday231215,
        head : '소금광산, 창업혁신공간 기술 IR 대회서 우수상 수상',
        date : '2023.12.15',
        link : 'https://www.fntoday.co.kr/news/articleView.html?idxno=309390'
    },
    {
        img : thebigdata231215,
        head : '소금광산, 2024년 데이터바우처 공급기업으로 지정',
        date : '2023.12.15',
        link : 'https://www.thebigdata.co.kr/view.php?ud=2023121417111554666cf2d78c68_23'
    },
    {
        img : ajunews231204,
        head : '하남스타트업캠퍼스 입주기업 소금광산, 킹고스프링과 투자계약 체결',
        date : '2023.12.04',
        link : 'https://www.ajunews.com/view/20231204161046721'
    },
    {
        img : cbc231204,
        head : '하남스타트업캠퍼스 ‘소금광산’ 킹고스프링과 투자 계약…“스케일업 기회 되길”',
        date : '2023.12.04',
        link : 'https://www.cbci.co.kr/news/articleView.html?idxno=454478'
    },
    {
        img : hanamilbo231204,
        head : '하남스타트업, 소금광산 킹고스프링 투자계약',
        date : '2023.12.04',
        link : 'http://www.hanamilbo.net/news/articleView.html?idxno=9240'
    },
    {
        img : shinailbo231204,
        head : '하남스타트업캠퍼스 입주기업 소금광산, 킹고스프링과 투자계약 체결',
        date : '2023.12.04',
        link : 'http://www.shinailbo.co.kr/news/articleView.html?idxno=1795759'
    },
    {
        img : newsfim231204,
        head : '소금광산, TIPS 운영사 킹고스프링과 투자 계약 체결',
        date : '2023.12.04',
        link : 'https://www.newspim.com/news/view/20231204000601'
    },
    {
        img : dailyEconomy231116,
        head : '지에이월드, 소금광산과 ‘보험 영업을 위한 디지털 기술 기반 온라인 서비스 개발’ 업무협약 체결',
        date : '2023.11.16',
        link : 'http://www.kdpress.co.kr/news/articleView.html?idxno=124361'
    },
    {
        img : startup231031,
        head : '데이터 솔루션 기업 ‘소금광산‘, 성남시 학교 밖 청소년들의 자립을 위한 ‘데이터 기반 진로탐색 솔루션’ 선보여',
        date : '2023.10.31',
        link : 'https://www.startupdaily.kr/news/articleView.html?idxno=4198'
    },
    {
        img : dailyEconomy230814,
        head : '학교 밖 청소년 자립 위한 메타버스 가상공간 플랫폼 업무협약 체결',
        date : '2023.08.14',
        link : 'http://www.kdpress.co.kr/news/articleView.html?idxno=121818'
    },
    {
        img : tokenPost220723,
        head : '[토큰포스트 인터뷰] 유창현 소금광산 CEO “개인과 기업이 WIN-WIN 하는 데이터 거래를 만들어 갑니다”',
        date : '2022.07.23',
        link : 'https://www.tokenpost.kr/article-100312'
    }

]