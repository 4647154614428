import styled from "styled-components";


const ModalButtonSection = ({ isSmallScreen, activeButton, setActiveButton, isSticky, isHidden, buttonSectionRef }) => {

    return (
        <ButtonSection 
            isSmallScreen={isSmallScreen}
            isSticky={isSticky}
            isHidden={isHidden}
            ref={buttonSectionRef}
            >
            <Button 
                isSmallScreen={isSmallScreen}
                className={activeButton === 'user' ? 'active' : ''}
                onClick={() => setActiveButton('user')}
            >
                사용자 화면
            </Button>
            <Button 
                isSmallScreen={isSmallScreen}
                className={activeButton === 'admin' ? 'active' : ''}
                onClick={() => setActiveButton('admin')}
            >
                관리자 화면
            </Button>
        </ButtonSection>
    );
}

export default ModalButtonSection;


const ButtonSection = styled.div`
    display: flex;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '6px' : '12px'};
    backdrop-filter: blur(1px);
    margin: ${({ isSmallScreen }) => isSmallScreen ? '0 20px' : '0 80px'};
    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '16px' : '80px'};

    // position: ${({ isSticky }) => (isSticky ? 'sticky' : 'relative')};
    // top: 0;
    // z-index: 10;
    
    // opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
    // visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
    // transition: opacity 0.3s ease, visibility 0.3s ease;

`;

const Button = styled.button`
    background-color: ${({ theme }) => theme.colors.gray6};
    padding: ${({ isSmallScreen }) => isSmallScreen ? '6.5px 14px' : '13px 28px'};
    border-radius: ${({ isSmallScreen }) => isSmallScreen ? '20px' : '40px'};

    font-size: ${({ isSmallScreen }) => isSmallScreen ? '14px' : '16px'};
    font-weight: ${({ isSmallScreen }) => isSmallScreen ? '500' : '600'};

    &.active {
        font-weight: 700;
        background-color: ${({ theme }) => theme.colors.main1};
        color: ${({ theme }) => theme.colors.white};
    }
`;