import styled from "styled-components";
import arrowRight from '../../img/arrow/arrow_right_short.svg';
import { Link } from 'react-router-dom';
import { OpacityAnimation } from "../../styled/common";
import useScrollVisible from "../../hooks/useScrollVisible";
import { useRef } from "react";
import useScreenSize from "../../hooks/useScreenSize";


const ServiceInquiry = () => {

    const { isSmallScreen } = useScreenSize();


    return (
        <ToInquiryButton isSmallScreen={isSmallScreen}>
            <Text isSmallScreen={isSmallScreen}>
                소금광산의 솔루션이 궁금하다면?
            </Text>
            <div>
                <LinkButton to="/inquiry" isSmallScreen={isSmallScreen}>
                    <span className={`${isSmallScreen && 'h-22'}`}>
                        서비스 문의하기
                    </span>
                    <img src={arrowRight} />
                </LinkButton>
            </div>
        </ToInquiryButton>
    );

}

export default ServiceInquiry;


const ToInquiryButton = styled.div`
    width: ${ ({isSmallScreen}) => isSmallScreen ? '320px' : '980px' };
    height: ${ ({isSmallScreen}) => isSmallScreen ? '122px' : '169px' };
    background: linear-gradient(92deg, #039 0%, #06F 50%, #00E1D2 100%);
    padding: ${ ({isSmallScreen}) => isSmallScreen ? '20px' : '52.5px 100px 52.5px 106px' };
    margin: 0 auto;

    letter-spacing: -0.24px;
    border-radius: 30px;

    display: flex;
    flex-direction: ${ ({isSmallScreen}) => isSmallScreen ? 'column' : 'row' };
    justify-content: flex-end;
    align-items: center;
    gap: ${ ({isSmallScreen}) => isSmallScreen ? '12px' : '177px' };
`;

const Text = styled.div`
    font-size: ${ ({isSmallScreen}) => isSmallScreen ? '16px' : '28px' };
    font-weight: 600;
    color: ${ ({theme}) => theme.colors.white };
`;

const LinkButton = styled(Link)`
    width: ${ ({isSmallScreen}) => isSmallScreen ? '280px' : '230px' };
    height: ${ ({isSmallScreen}) => isSmallScreen ? '48px' : '64px' };
    background-color: ${ ({theme}) => theme.colors.white };
    padding: ${ ({isSmallScreen}) => isSmallScreen ? '12px 28px 12px 30px' : '20px 32px 20px 36px' };

    font-size: ${ ({isSmallScreen}) => isSmallScreen ? '16px' : '20px' };
    font-weight: 600;
    color: ${ ({theme}) => theme.colors.gray4 };

    border-radius: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${ ({isSmallScreen}) => isSmallScreen ? '16px' : '12px' };
`;