import useScreenSize from '../../hooks/useScreenSize';
// import bgImage from '../../img/customer_satisfaction_image.png';
import bgImage from '../../img/customer_satisfaction_image_copy.png';
import styled from 'styled-components';
import { krMain } from '../../datas/data.js';
import { OpacityAnimation } from '../../styled/common.js';
import useScrollVisible from '../../hooks/useScrollVisible.js';
import { theme } from '../../utils/palette.js';
import useScrollPosition from '../../hooks/useScrollPosition.js';
import { useEffect, useRef, useState } from 'react';


const CustomerSatisfactionBg = () => {

    const { isSmallScreen, isLargeScreen } = useScreenSize();

    const sectionRef = useRef(null);
    
    const scrollPosition = useScrollPosition();
    const [ translateValue, setTranslateValue ] = useState(0);

    useEffect(() => {
        if (sectionRef.current) {
            const sectionTop = sectionRef.current.offsetTop;
            const sectionHeight = sectionRef.current.clientHeight;

            const startScroll = sectionTop - sectionHeight * 1.5;
            const endScroll = sectionTop;

            if (scrollPosition >= startScroll && scrollPosition <= endScroll) {
                const progress = (scrollPosition - startScroll) / (endScroll - startScroll);
                setTranslateValue(progress * 100);
            } else if (scrollPosition < startScroll) {
                setTranslateValue(0);
            } else if (scrollPosition > endScroll) {
                setTranslateValue(100);
            }
        }
    }, [scrollPosition, isSmallScreen]);
    
    const bgHeight = isSmallScreen ? '250px' : isLargeScreen ? '750px' : '520px';

    const title = isSmallScreen ? krMain.satisfactionBg.title.m : krMain.satisfactionBg.title.pc;
    const subCopy = isSmallScreen ? krMain.satisfactionBg.subCopy.m : krMain.satisfactionBg.subCopy.pc;
    const textBoxMargin = isSmallScreen ? '46px auto 0 auto' : isLargeScreen ? '260px auto 0 auto' : '180px auto 0 auto';

    const scrollCutBgColor = theme.colors.white;


    return (
        <section ref={sectionRef}>
            <BgCrop height={bgHeight}>
                <img src={bgImage} className="block w-full h-full object-cover" />
                <TextBox margin={textBoxMargin}>
                    <H1 
                        dangerouslySetInnerHTML={{ __html: title }}
                        isSmallScreen={isSmallScreen}
                    />
                    <P 
                        isSmallScreen={isSmallScreen}
                        dangerouslySetInnerHTML={{ __html: subCopy }}
                    />
                </TextBox>
                <ScrollCutBox style={{ left : '0', transform : `translate3d(-${translateValue}%, 0px, 0px)` }} bgColor={scrollCutBgColor} />
                <ScrollCutBox style={{ right : '0', transform : `translate3d(${translateValue}%, 0px, 0px)` }} bgColor={scrollCutBgColor} />
            </BgCrop>
        </section>
    );

}

export default CustomerSatisfactionBg;


const BgCrop = styled.div`
    width: 100%;
    height: ${ ({height}) => height };

    display: flex;
    justify-content: center;
    
    position: relative;
    overflow: hidden;
`;

const TextBox = styled.div`
    color: #FFFFFF;
    text-align: center;
    white-space: nowrap;

    margin: ${ ({margin}) => margin };
    
    position: absolute;
`;

const H1 = styled.h1`
    font-size: ${ ({isSmallScreen}) => isSmallScreen ? '20px' : '28px' };
    font-weight: 700;

    margin-bottom: 16px;
`;

const P = styled.p`
    font-size: ${ ({isSmallScreen}) => isSmallScreen ? '14px' : '18px' };
    
    margin-bottom: 16px;
`;

const ScrollCutBox = styled.div`
    width: calc((100vw - 1040px) / 2);
    height: 100%;
    background-color: ${ ({bgColor}) => bgColor };

    position: absolute;
    top: 0;
`;