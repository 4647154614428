import styled from "styled-components";
import React, { useState } from "react";
import CustomerCaseCard from "./CustomerCaseCard";
import { useMediaQuery } from "react-responsive";
import { customerCasesData } from "../../datas/customerCaseData.js";
import useScreenSize from "../../hooks/useScreenSize.js";
import usePreventScroll from "../../hooks/usePreventScroll.js";
import CustomerCaseModal from "./modal/CustomerCaseModal.js";
import closeButtonLarge from '../../img/icon/close_modal.png';
import closeButtonSmall from '../../img/icon/close.png';
import { modalCasesData } from "../../datas/customerCaseData.js";
import usePreloadImages from "../../hooks/usePreloadImages.js";


const CustomerCase = React.forwardRef((props, ref) => {

    const { isSmallScreen } = useScreenSize();
    const isUnderMaxwith = useMediaQuery({ minWidth: 1181 });

    const [ customerCases ] = useState(customerCasesData);
    const [ isModalVisible, setIsModalVisible ] = useState(false);
    const [ isClosing, setIsClosing ] = useState(false);
    const [ selectedCase, setSelectedCase ] = useState(null);
    const [ isFullyVisible, setIsFullyVisible ] = useState(false);

    const handleOpenModal = (index) => {
        setSelectedCase(modalCasesData[index]);
        setIsModalVisible(true);
        setIsClosing(false);

        setTimeout(() => {
            setIsFullyVisible(true);
        }, 10);
    };
 
    const handleCloseModal = () => {
        setIsClosing(true);
        setIsFullyVisible(false);
    };

    const handleAnimationEnd = () => {
        if (isClosing) {
            setIsModalVisible(false);
            setSelectedCase(null);
        }
    };

    usePreventScroll(isModalVisible, isClosing);
    usePreloadImages();
        

    return (
        <>
            <section className={`${isSmallScreen ? 'min-m-320' : 'max-w-1180'} mx-auto`} ref={ref}>
                <H1 ref={ref} isSmallScreen={isSmallScreen}>
                    고객 사례
                </H1>
                <CardContainer isUnderMaxwith={isUnderMaxwith}>
                    {customerCases.map((customerCase, i) => (
                        <CustomerCaseCard
                            img={customerCase.img}
                            title={customerCase.title}
                            subCopy={customerCase.subCopy}
                            firstChip={customerCase.firstChip}
                            secondChip={customerCase.secondChip}
                            isSmallScreen={isSmallScreen}
                            key={i}
                            index={i}
                            handleOpenModal={handleOpenModal}
                        />
                    ))}
                </CardContainer>
            </section>
            
            {
                isModalVisible && (
                    <Overlay isVisible={isFullyVisible} onClick={handleCloseModal}>
                        <Modal 
                            onClick={(e) => e.stopPropagation()} 
                            onAnimationEnd={handleAnimationEnd}
                        >
                            <CloseButton isSmallScreen={isSmallScreen}>
                                <button onClick={handleCloseModal}>
                                    <img src={isSmallScreen ? closeButtonSmall : closeButtonLarge} className="block w-full h-full" />
                                </button>
                            </CloseButton>
                            {selectedCase && <CustomerCaseModal selectedCase={selectedCase} resetToUser={isClosing} />}
                        </Modal>
                    </Overlay>
                )
            }
        </>
    );

});

export default CustomerCase;


const H1 = styled.h1`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '20px' : '28px'};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray4};
    text-align: center;
    line-height: 140%;

    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '24px' : '36px'};
`;

const CardContainer = styled.section`
    width: 100%;

    display: flex;
    justify-content: ${ ({isUnderMaxwith}) => isUnderMaxwith ? 'flex-start' : 'center' };
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: ${ ({isUnderMaxwith}) => isUnderMaxwith ? '14px' : '20px'};
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 12vh 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
    transition: all 0.3s ease-in-out;
`;

const Modal = styled.div`
    max-width: 1112px;
    width: ${({ isSmallScreen }) => isSmallScreen ? '' : '78vw'};
    height: 76vh;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;

    position: relative;
`;

const CloseButton = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '24px' : '32px'};
    height: ${({ isSmallScreen }) => isSmallScreen ? '24px' : '32px'};

    position: absolute;
    top: ${({ isSmallScreen }) => isSmallScreen ? '16px' : '32px'};
    right: ${({ isSmallScreen }) => isSmallScreen ? '16px' : '32px'};
    z-index: 10;
`;
