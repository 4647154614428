import styled from "styled-components";
import useScreenSize from "../../hooks/useScreenSize";


const Required = () => {

    const { isSmallScreen } = useScreenSize();

    return (
        <Span isSmallScreen={isSmallScreen}>*</Span>
    );

}

export default Required;


const Span = styled.span`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '18px' : '20px'};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.accent4};
`;