import styled from "styled-components";
import useScreenSize from "../../hooks/useScreenSize";
import { theme } from "../../utils/palette";
import Content from "../common/Content";
import { useRef, useState } from "react";
import { cultureData } from "../../datas/data";
import useScrollVisible from "../../hooks/useScrollVisible";
import { OpacityAnimation, RefloatAnimation } from "../../styled/common";

const Culture = () => {

    const { isSmallScreen } = useScreenSize();
    const [ cultures ] = useState(cultureData);


    return (
        <section className={`${isSmallScreen ? 'pt-80' : 'pt-200'}`}>
            <Content 
                title={'OUR CULTURE'}
                color={theme.colors.gray4}
                isSmallScreen={isSmallScreen}
            />
            <CultureList isSmallScreen={isSmallScreen}>
            {
                cultures.map((culture, index) => 
                    <CultureCard isSmallScreen={isSmallScreen} key={index}>
                        <Background>
                            <img src={isSmallScreen ? culture.img.m : culture.img.pc} className="block w-full h-full rounded-20" />
                        </Background>
                        <div className={`flex ${isSmallScreen ? 'gap-x-4' : 'gap-x-8'}`}>
                            <Subject 
                                dangerouslySetInnerHTML={{ 
                                    __html: isSmallScreen ? culture.subject.m : culture.subject.pc
                                }}
                                isSmallScreen={isSmallScreen}
                            />
                            { 
                                culture.sub && 
                                <div className={`${isSmallScreen ? 'text-12' : 'text-16'} font-bold text-white self-end`}>
                                    { culture.sub }
                                </div>
                            }
                        </div>
                        <Title 
                            dangerouslySetInnerHTML={{ 
                                __html: isSmallScreen ? culture.content.title.m : culture.content.title.pc
                            }}
                            isSmallScreen={isSmallScreen}
                        />
                        <CultureContent isSmallScreen={isSmallScreen}>
                        <div 
                            dangerouslySetInnerHTML={{ 
                                __html: isSmallScreen ? culture.content.m[0] : culture.content.pc[0]
                            }} 
                        />
                        <div 
                            dangerouslySetInnerHTML={{ 
                                __html: isSmallScreen ? culture.content.m[1] : culture.content.pc[1]
                            }}
                        />
                        </CultureContent>
                    </CultureCard>
                )
            }
            </CultureList>
        </section>
    );

}

export default Culture;


const CultureList = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

const CultureCard = styled.article`
    width: ${({ isSmallScreen }) => isSmallScreen ? '320px' : '1178px'};
    height: ${({ isSmallScreen }) => isSmallScreen ? '312px' : '312px'};
    border-radius: 20px;

    color: ${({ theme }) => theme.colors.white};
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '16px' : '22px'};

    position: relative;
`;

const Background = styled.div`
    width: inherit;
    height: inherit;
    background: linear-gradient(0deg, var(--text-focusVisible, rgba(0, 0, 0, 0.30)) 0%, var(--text-focusVisible, rgba(0, 0, 0, 0.30)) 100%), 
                url(${({ bgImg }) => bgImg}) lightgray 0px -116.667px / 100% 166.384% no-repeat;
    border-radius: inherit;

    position: absolute;
    z-index: -5;
`;

const Subject = styled.h1`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '20px' : '24px'};
    font-weight: 700;
    line-height: 20px;
`;

const Title = styled.h1`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '16px' : '18px'};
    font-weight: 600;
    line-height: 140%;
`;

const CultureContent = styled.p`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '14px' : '16px'};
    font-weight: 400;
    line-height: 20px;

    display: flex;
    flex-direction: column;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '8px' : '22px'};
`;