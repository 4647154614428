import { theme } from "../../utils/palette.js";
import Content from "../common/Content.js";
import PartnerCards from "./PartnerCards.js";
import { krMain } from "../../datas/data.js";
import { Section } from "../../styled/common.js";
import useScreenSize from "../../hooks/useScreenSize.js";


const Patners = ({ height }) => {

    const { isSmallScreen } = useScreenSize();

    const title = isSmallScreen ? krMain.partner.title.m : krMain.partner.title.pc;
    const subCopy = isSmallScreen ? krMain.partner.subCopy.m : krMain.partner.subCopy.pc;
    const color = theme.gray4;
    const sectionHeight = isSmallScreen ? height : '620px';


    return (
        <Section className="flex-col overflow-hidden" height={sectionHeight}>
            <Content 
                title={title}
                subCopy={subCopy}
                color={color}
                isSmallScreen={isSmallScreen}
            />
            <PartnerCards isSmallScreen={isSmallScreen} />
        </Section>
    );

}

export default Patners;