import { useState } from "react";
import Required from "./Required";
import TextInput from "./TextInput";


const TextInputSection = ({ formData, handleChange, isSmallScreen, formErrors }) => {

    return (
        <div className={`flex ${isSmallScreen ? 'flex-col gap-y-8 mt-24' : 'flex-row justify-between mt-40'}`}>
            <div>
                <span className={`${isSmallScreen ? 'text-16 font-bold' : 'text-20 font-semibold'} text-gray4`}>
                    정보
                </span>
                <Required>*</Required>
            </div>
            <div className={`flex flex-col ${isSmallScreen ? 'gap-y-8' : 'gap-y-12'}`}>
                <TextInput
                    label="기업/기관명"
                    type="text"
                    name="organizationName"
                    value={formData.organizationName}
                    handleChange={handleChange}
                    isSmallScreen={isSmallScreen}
                    hasError={formErrors.organizationName}
                />
                <TextInput
                    label="성명"
                    type="text"
                    name="name"
                    value={formData.name}
                    handleChange={handleChange}
                    isSmallScreen={isSmallScreen}
                    hasError={formErrors.name}
                />
                <TextInput
                    label="이메일"
                    type="email"
                    name="email"
                    value={formData.email}
                    handleChange={handleChange}
                    isSmallScreen={isSmallScreen}
                    hasError={formErrors.email}
                />
                <TextInput
                    label="연락처"
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    handleChange={handleChange}
                    isSmallScreen={isSmallScreen}
                    hasError={formErrors.phoneNumber}
                    placeholder={"- 없이 입력해주세요. (ex : 01012345678)"}
                />
            </div>
        </div>
    );
    
}

export default TextInputSection;