import styled from "styled-components";


const ToastMessage = ({ message, isSmallScreen }) => {

    return (
        <Message isSmallScreen={isSmallScreen}>
            { message }
        </Message>
    );

}

export default ToastMessage;


const Message = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '320px' : '580px'};
    height: 38px;
    background-color: rgba(47, 47, 47, 0.8);
    padding: 8px;
    margin-top: 12px;
    border-radius: 8px;

    font-size: 16px;
    color: ${({ theme }) => theme.colors.white};
    line-height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
`;