import styled from "styled-components";
import { krSolutionAdmin } from "../../datas/data";
import dashboardImg from '../../img/solution_dashboard.svg';
import bigdataImg from '../../img/solution_bigdata.svg';
import smalldataImg from '../../img/solution_smalldata.svg';
import analysisImg1 from '../../img/solution_anaysis_1.svg';
import analysisImg2 from '../../img/solution_anaysis_2.svg';
import { opacityAnimation } from "../../styled/common";


const SmallDataAdmin = ({ isSmallScreen }) => {

    return (
        <Section isSmallScreen={isSmallScreen}>

            <Container isSmallScreen={isSmallScreen}>
                <Content 
                    title={isSmallScreen ? krSolutionAdmin.dashboard.title.m : krSolutionAdmin.dashboard.title.pc}
                    subCopy={isSmallScreen ? krSolutionAdmin.dashboard.subCopy.m : krSolutionAdmin.dashboard.subCopy.pc}
                    isSmallScreen={isSmallScreen}
                />
                <ImgContainer isSmallScreen={isSmallScreen}>
                    <Img 
                        src={dashboardImg} 
                        className={`${isSmallScreen ? 'w-230' : 'w-550'} mx-auto`} 
                        top={isSmallScreen ? '13.5px' : '33px'}
                    />
                </ImgContainer>
            </Container>

            <Container isSmallScreen={isSmallScreen}>
                <Content 
                    title={isSmallScreen ? krSolutionAdmin.bigdata.title.m : krSolutionAdmin.bigdata.title.pc}
                    subCopy={isSmallScreen ? krSolutionAdmin.bigdata.subCopy.m : krSolutionAdmin.bigdata.subCopy.pc}
                    isSmallScreen={isSmallScreen}
                />
                <ImgContainer isSmallScreen={isSmallScreen}>
                    <Img 
                        src={bigdataImg} 
                        className={`${isSmallScreen ? 'w-167 h-118' : 'w-408 h-290'} mx-auto`}
                        top={isSmallScreen ? '7px' : '29px'}
                    />
                </ImgContainer>
            </Container>

            <Container isSmallScreen={isSmallScreen}>
                <Content 
                    title={isSmallScreen ? krSolutionAdmin.smalldata.title.m : krSolutionAdmin.smalldata.title.pc}
                    subCopy={isSmallScreen ? krSolutionAdmin.smalldata.subCopy.m : krSolutionAdmin.smalldata.subCopy.pc}
                    isSmallScreen={isSmallScreen}
                />
                <ImgContainer isSmallScreen={isSmallScreen}>
                    <Img 
                        src={smalldataImg} 
                        className={`${isSmallScreen ? 'w-209' : 'w-510'} mx-auto`}
                        top={isSmallScreen ? '8px' : '19px'}
                    />
                </ImgContainer>
            </Container>

            <Container isSmallScreen={isSmallScreen}>
                <Content 
                    title={isSmallScreen ? krSolutionAdmin.analysis.title.m : krSolutionAdmin.analysis.title.pc}
                    subCopy={isSmallScreen ? krSolutionAdmin.analysis.subCopy.m : krSolutionAdmin.analysis.subCopy.pc}
                    isSmallScreen={isSmallScreen}
                />
                <ImgContainer isSmallScreen={isSmallScreen}>
                    <div className="flex justify-center">
                        <Img 
                            src={analysisImg1} 
                            className={`${isSmallScreen ? 'w-140' : 'w-330'}`}
                            top={isSmallScreen ? '5.5px' : '20px'}
                            left={isSmallScreen ? '6px' : '13px'} 
                        />
                        <Img 
                            src={analysisImg2} 
                            className={`${isSmallScreen ? 'w-140' : 'w-330'}`}
                            top={isSmallScreen ? '5.5px' : '20px'}
                            left={isSmallScreen ? '-6px' : '-13px'} 
                        />
                    </div>
                </ImgContainer>
            </Container>

        </Section>
    );

}

export default SmallDataAdmin;


const Section = styled.section`
    display: flex;
    flex-direction: column;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '12px' : '36px'};
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '6px' : '16px'};
`;

const Content = ({ title, subCopy, isSmallScreen }) => {
    return (
        <ContentContainer isSmallScreen={isSmallScreen}>
            <Title 
                dangerouslySetInnerHTML={{ __html: title }} 
                isSmallScreen={isSmallScreen}
            />
            <SubCopy 
                dangerouslySetInnerHTML={{ __html: subCopy }} 
                isSmallScreen={isSmallScreen}
            />
        </ContentContainer>
    );
}

const ContentContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '6px' : '12px'};
`;

const Title = styled.h1`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '13px' : '24px'};
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray4};
    line-height: 160%;
    letter-spacing: -0.36px;
    white-space: nowrap;

    align-self: flex-start;
`;

const SubCopy = styled.p`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '18px'};
    color: ${({ theme }) => theme.colors.gray4};
    text-align: left;
    letter-spacing: -0.27px;
`;

const ImgContainer = styled.div`
    width: 100%;
    height: ${({ isSmallScreen }) => isSmallScreen ? '124px' : '300px'};
    background-color: ${({ theme }) => theme.colors.gray1};

    border-radius: ${({ isSmallScreen }) => isSmallScreen ? '16.5px' : '40px'};

    overflow: hidden;
`;

const Img = styled.img`
    display: block;

    position: relative;
    top: ${({ top }) => top};
    left: ${({ left }) => left ? left : '0'};
`;