import { useRef } from "react";
import { CustomerInsight, CustomerSatisfactionBg, Patners, ProductUseSolution, ServiceInquiry, SmallData, SmallDataValueBg } from "../components/mainPage";


const MainPage = ({ height, headerHeight }) => {

    const smallDataRef = useRef(null);


    return (
        <div style={{ marginTop : headerHeight }}>
            <SmallDataValueBg height={height} nextSectionRef={smallDataRef} headerHeight={headerHeight} />
            <SmallData height={height} ref={smallDataRef} />
            <ProductUseSolution height={height} />
            <CustomerInsight height={height} />
            <CustomerSatisfactionBg />
            <Patners height={height} />
            <ServiceInquiry />
        </div>
    );

}

export default MainPage;