export const history = [

    {
        year : '2024',
        content : [
            {
                month : '7',
                content : '중소벤처기업부 창업성장기술개발사업(TIPS) 선정'
            },
            {
                month : '4',
                content : '중소벤처기업부 수출바우처 수요기업 선정(튼튼한 내수기업)'
            },
            {
                month : '3',
                content : '신용보증기금 리틀펭귄 프로그램 기업 선정'
            }
        ]
    },
    {
        year : '2023',
        content : [
            {
                month : '12',
                content : '한국데이터산업진흥원 데이터바우처 공급기업 지정'
            },
            {
                month : '11',
                content : '(주)킹고스프링 Seed 투자 계약 체결'
            },
            {
                month : '11',
                content : '(주)더탑컨설팅 Seed 투자 계약 체결'
            },
            {
                month : '10',
                content : '충남정보문화산업진흥원 천안그린스타트업타운 입주기업 선정'
            },
            {
                month : '9',
                content : '킹고스프링 Spring Board 배치 프로그램 선정'
            },
            {
                month : '7',
                content : '연구개발특구진흥재단 Science-Biz CARE 프로그램 선정'
            },
            {
                month : '6',
                content : '하남스타트업캠퍼스 2023 스타트업 사업화 패키지 지원사업 선정'
            },
            {
                month : '6',
                content : '충남테크노파크 혁신기술 시제품 제작 지원사업 선정'
            },
            {
                month : '6',
                content : '비즈니스 모델 특허 등록 결정(등록번호: 10-2541464)'
            },
            {
                month : '3',
                content : '기술보증기금 기보벤처캠프 12기 선정'
            },
            {
                month : '1',
                content : '충남정보문화산업진흥원✕JB벤처스 INNOST 챌린지 그라운드 2기 선정'
            }
        ]
    },
    {
        year : '2022',
        content : [
            {
                month : '12',
                content : '여성가족부 가족친화기업 인증 획득'
            },
            {
                month : '11',
                content : '경기도 면접수당 지급기업 인증 획득'
            },
            {
                month : '11',
                content : '교보생명 ✕ 한양대학교 CONNECT U 오픈이노베이션 프로그램 선정'
            },
            {
                month : '9',
                content : '제5회 핀테크 아이디어 공모전 우수상 수상'
            },
            {
                month : '7',
                content : 'ISO 9001 품질경영시스템 인증 획득'
            },
            {
                month : '7',
                content : '혁신성장유형 벤처기업 인증 획득'
            },
            {
                month : '7',
                content : '중소벤처기업부 창업성장기술 개발사업(디딤돌) 선정'
            },
            {
                month : '6',
                content : '정보통신산업진흥원 K-Global 스타트업 공모전 선정'
            },
            {
                month : '6',
                content : '충남테크노파크 혁신기술 시제품 제작 지원사업 선정'
            },
            {
                month : '4',
                content : '한국핀테크지원센터 제 28차 핀테크데모데이 본선 입상'
            },
            {
                month : '3',
                content : '하남시 하남스타트업캠퍼스 입주기업 선정'
            },
            {
                month : '2',
                content : '모바일 앱 서비스 솔티 SALTi MVP 출시'
            }
        ]
    },
    {
        year : '2021',
        content : [
            {
                month : '9',
                content : '충남산학융합원 MC 회원사 등록'
            },
            {
                month : '8',
                content : '기업부설연구소 인가 (서울시 마포구)'
            },
            {
                month : '6',
                content : '오픈블록체인DID협회 정회원사 등록'
            }
        ]
    },
    {
        year : '2020',
        content : [
            {
                month : '6',
                content : '중소벤처기업부 주관 예비창업패키지지원 사업 선정'
            },
            {
                month : '5',
                content : '주식회사 소금광산 창립'
            },
        ]
    }


]