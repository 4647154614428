import useScreenSize from "../../hooks/useScreenSize";
import { theme } from "../../utils/palette";
import Content from "../common/Content";
import InquiryForm from "./InquiryForm";


const Inquiry = () => {

    const { isSmallScreen } = useScreenSize();

    const title = {
        pc : `아래 정보로 문의해주시면 24시간 내로 연락드립니다.`,
        m : `아래 정보로 문의해주시면<br/>
            24시간 내로 연락드립니다.`
    }


    return (
        <section className={`${isSmallScreen ? 'pt-48' : 'pt-80'}`}>
            <Content 
                title={isSmallScreen ? title.m : title.pc}
                color={theme.colors.gray4}
                isSmallScreen={isSmallScreen}
            />
            <InquiryForm isSmallScreen={isSmallScreen} />
        </section>
    );

}

export default Inquiry;