import { useRef } from "react";
import { History, InfoMainBg, Members, OurMission, PressRelease } from "../components/infoPage";
import Culture from "../components/infoPage/Culture";


const InfoPage = ({ height, headerHeight }) => {

    const ourMissionRef = useRef(null);


    return (
        <div style={{marginTop : headerHeight}}>
            <InfoMainBg />
            <OurMission height={height} ref={ourMissionRef} />
            <PressRelease />
            <History />
            <Members />
            <Culture />
        </div>
    );

}

export default InfoPage;


