import Olive from '../img/members/Olive.png'
import Jason from '../img/members/Jason.png'
import Austin from '../img/members/Austin.png'
import Wake from '../img/members/Wake.png'
import Elena from '../img/members/Elena.png'
import Anne from '../img/members/Anne.png'
import Sancho from '../img/members/Sancho.png'
import Gorden from '../img/members/Gorden.png'
import Philip from '../img/members/Philip.png'
import Julia from '../img/members/Julia.png'
import oliveSymbol from '../img/members/olive_symbol.png';
import jasonSymbol from '../img/members/jason_symbol.png';
import austinSymbol from '../img/members/austin_symbol.png';
import wakeSymbol from '../img/members/wake_symbol.png';
import elenaSymbol from '../img/members/elena_symbol.png';
import anneSymbol from '../img/members/anne_symbol.png';
import sanchoSymbol from '../img/members/sancho_symbol.png';
import gordenSymbol from '../img/members/gorden_symbol.png';
import philipSymbol from '../img/members/philip_symbol.png';
import juliaSymbol from '../img/members/julia_symbol.png';


export const membersData = [

    {
        img : Olive,
        nickname : '대표광부',
        krName : '유창현',
        egName : 'Olive',
        headquarters : '경영본부 ∙ 사업본부',
        position : 'CEO',
        symbol : {
            img : oliveSymbol,
            size : {
                pc : 'w-102 h-94 mt-41 mb-29',
                m : 'w-67 h-62 mt-28 mb-15'
            }
        },
        clickedBg : '#28A4FF',
        rolling : [
            '소금광산의 든든한 리더',
            '바른생활 사나이',
            '최강 GiVER',
            'Holly',
            '인자한 리더',
            '뭐든 해내는 리더',
            'INiTiATOR',
            '넘치는 배려'
        ]
    },
    {
        img : Jason,
        nickname : '성장광부',
        krName : '박규남',
        egName : 'Jason',
        headquarters : '경영본부',
        position : 'CGO',
        symbol : {
            img : jasonSymbol,
            size : {
                pc : 'w-107 h-76 mt-48 mb-41',
                m : 'w-71 h-51 mt-33 mb-21'
            }
        },
        clickedBg : '#7188F1',
        rolling : [
            '제일 쎈 카드',
            '능력있는 페이스 메이커',
            '제갈량',
            '지니어슨',
            '듬직한 지도자',
            'The joker in the pack',
            '멀리 내다보는 조언자',
            '큰 그림을 그리는 설계자'
        ]
    },
    {
        img : Austin,
        nickname : '기술광부',
        krName : '김규동',
        egName : 'Austin',
        headquarters : 'IT본부 ∙ 솔루션개발팀',
        position : 'CTO ∙ 연구소장',
        symbol : {
            img : austinSymbol,
            size : {
                pc : 'w-86 h-95 mt-41 mb-28',
                m : 'w-57 h-63 mt-28 mb-14'
            }
        },
        clickedBg : '#5A86FF',
        rolling : [
            '개발의 정석',
            '최고의 책임감과 놀라운 개발 능력',
            '묵묵하게 최선을 다하는 개발자',
            '묵직한 개발자',
            '개발 기둥',
            '끈기 넘치는 개발자',
            '믿을맨',
            '다크템플러'
        ]
    },
    {
        img : Wake,
        nickname : '소통광부',
        krName : '안우혁',
        egName : 'Wake',
        headquarters : 'IT본부 ∙ 데이터개발팀',
        position : '본부장',
        symbol : {
            img : wakeSymbol,
            size : {
                pc : 'w-86 h-95 mt-41 mb-28',
                m : 'w-85 h-62 mt-31 mb-12'
            }
        },
        clickedBg : '#5377FA',
        rolling : [
            'All Round Player',
            '조율자',
            '길을 안내하는 지도자',
            '든든한 리더',
            '거품 제거기',
            '허리 역할',
            '우산 같은 사람',
            '안내자'
        ]
    },
    {
        img : Elena,
        nickname : '회계광부',
        krName : '박지숙',
        egName : 'Elena',
        headquarters : '경영본부 ∙ 경영지원팀',
        position : '팀장',
        symbol : {
            img : elenaSymbol,
            size : {
                pc : 'w-130 h-87 mt-45 mb-32',
                m : 'w-87 h-58 mt-30 mb-17'
            }
        },
        clickedBg : '#688DFA',
        rolling : [
            '회계 누나',
            '왕언니',
            '엄마',
            '따뜻한 어미새',
            '작지만 강한 언니',
            '칭찬의 여왕',
            '세심한 배려가 넘치는 분',
            '문서의 정석'
        ]
    },
    {
        img : Anne,
        nickname : '디자인광부',
        krName : '조예은',
        egName : 'Anne',
        headquarters : 'IT본부 ∙ 솔루션개발팀',
        position : '팀장',
        symbol : {
            img : anneSymbol,
            size : {
                pc : 'w-97 h-88 mt-36 mb-41',
                m : 'w-87 h-58 mt-30 mb-17'
            }
        },
        clickedBg : '#4F7BF9',
        rolling : [
            '효율 200% 디자이너',
            '센스 있는 디자이너',
            '속도감 넘치는 유니크와 탁월함',
            '냉철미',
            '신뢰할 수 있는 디자이너',
            '청소기',
            '카리스마 똑순이',
            '냉철한 판단과 기획'
        ]
    },
    {
        img : Sancho,
        nickname : '개발광부',
        krName : '권재현',
        egName : 'Sancho',
        headquarters : 'IT본부 ∙ 솔루션개발팀',
        position : 'Manager',
        symbol : {
            img : sanchoSymbol,
            size : {
                pc : 'w-138 h-97 mt-32 mb-37',
                m : 'w-92 h-65 mt-21 mb-19'
            }
        },
        clickedBg : '#596AD8',
        rolling : [
            '즐거운 에너자이저',
            '분위기 메이커',
            '반드시 해내는 사람',
            '긍정맨',
            '파워 청년',
            '공기청정기',
            '힘을 북돋는 천사',
            '긍정 파워'
        ]
    },
    {
        img : Gorden,
        nickname : '창조광부',
        krName : '조한결',
        egName : 'Gorden',
        headquarters : 'IT본부 ∙ 데이터개발팀',
        position : 'Manager',
        symbol : {
            img : gordenSymbol,
            size : {
                pc : 'w-83 h-101 mt-35 mb-29',
                m : 'w-55 h-67 mt-23 mb-15'
            }
        },
        clickedBg : '#6D71D6',
        rolling : [
            '똑똑한 에디',
            '장그래',
            '노력하는 러너자이저',
            '다재다능 인재',
            'Everything O.K!',
            '열정적인 능력자',
            '스마트한 매력',
            '배려왕'
        ]
    },
    {
        img : Philip,
        nickname : '프론트광부',
        krName : '이인기',
        egName : 'Philip',
        headquarters : 'IT본부 ∙ 솔루션개발팀',
        position : 'Manager',
        symbol : {
            img : philipSymbol,
            size : {
                pc : 'w-69 h-87 mt-43 mb-36',
                m : 'w-46 h-58 mt-28 mb-19'
            }
        },
        clickedBg : '#3F85FF',
        rolling : [
            '개발왕 슛돌이',
            '에너자이저 개발자',
            '은근과 끈기의 사나이',
            '성실의 정석',
            '친절한 완벽주의자',
            '삼선짬뽕 매니아',
            '알잘딱깔센',
            '착한 심성의 소유자'
        ]
    },
    {
        img : Julia,
        nickname : '인턴광부',
        krName : '구연우',
        egName : 'Julia',
        headquarters : 'IT본부 ∙ 데이터개발팀',
        position : 'Intern',
        symbol : {
            img : juliaSymbol,
            size : {
                pc : 'w-88 h-84 mt-41 mb-38',
                m : 'w-54 h-51 mt-31 mb-23'
            }
        },
        clickedBg : '#6D71D6',
        rolling : [
            '잠재력 덩어리',
            '똑똑이 막내 광부',
            '믿음직한 사기 인턴',
            '특별한 인재',
            '씩씩한 막내',
            '다이아몬드 원석',
            '성숙한 광부',
            '총명한 광부'
        ]
    }

]