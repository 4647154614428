import { useEffect } from "react";


export const usePreventScroll = (modalOpen, isClosing = undefined) => {

    useEffect(() => {
        if (modalOpen && !isClosing) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [modalOpen, isClosing]);

}

export default usePreventScroll;