import { krInfo } from "../../datas/data";
import useScreenSize from "../../hooks/useScreenSize";
import Content from "../common/Content";
import { theme } from "../../utils/palette";
import HistoryTable from "./HistoryTable";


const History = () => {

    const { isSmallScreen } = useScreenSize();
    
    const title = isSmallScreen ? krInfo.history.title.m : krInfo.history.title.pc;
    const subCopy = isSmallScreen? krInfo.history.subCopy.m : krInfo.history.subCopy.pc;
    const color = theme.colors.gray4;


    return (
        <section className={`bg-white ${isSmallScreen ? 'py-80' : 'py-200'}`}>
            <Content 
                title={title}
                subCopy={subCopy}
                color={color}
                isSmallScreen={isSmallScreen}
            />
            <HistoryTable isSmallScreen={isSmallScreen} />
        </section>
    );

}

export default History;