import styled from "styled-components";

const ModalContents = ({ selectedCase, isSmallScreen, activeButton }) => {

    const modalContents = activeButton === 'user' ? selectedCase.user : selectedCase.admin;


    return (
        modalContents.map((section, index) => (
            <Section 
                isSmallScreen={isSmallScreen} 
                bgColor={section.bgColor} 
                flexDirection={
                    section.reverse
                        ? isSmallScreen
                            ? 'column'
                            : 'row-reverse'
                        : isSmallScreen
                        ? 'column'
                        : section.flexDirection
                }
                alignItems={section.alignItems}
                key={index}
                >
                <TextBox 
                    isSmallScreen={isSmallScreen} 
                    width={isSmallScreen ? section.width?.m : section.width?.pc}
                    textAlign={section.textAlign}
                    paddingTop={isSmallScreen ? section.paddingTop?.m : section.paddingTop?.pc}
                    margin={isSmallScreen ? section.margin?.m : section.margin?.pc}
                    >
                    <Title 
                        dangerouslySetInnerHTML={{ __html: section.title }} 
                        isSmallScreen={isSmallScreen}
                    />
                    <SubCopy 
                        dangerouslySetInnerHTML={{ __html: section.subCopy }} 
                        isSmallScreen={isSmallScreen}
                    />
                </TextBox>
                {
                    section.img && (
                        <ImgBox width={isSmallScreen ? section.imgWidth?.m : section.imgWidth?.pc}>
                            <img 
                                src={isSmallScreen ? section.img.m : section.img.pc}
                            />
                        </ImgBox>
                    )
                }
            </Section>
        ))
    )
}

export default ModalContents;


const Section = styled.section`
    width: 100%;
    background-color: ${({ bgColor, theme }) => bgColor ? bgColor : theme.colors.white};
    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '60px' : '100px'};

    display: flex;
    flex-direction: ${({ isSmallScreen, flexDirection }) => isSmallScreen ? 'column' : flexDirection ? flexDirection : 'column'};
    justify-content: center;
    align-items: ${({ alignItems }) => alignItems ? alignItems : 'center'};

    &:last-child {
        margin-bottom: 0;
    }
`;


const TextBox = styled.div`
    width: ${({ isSmallScreen, width }) => isSmallScreen ? '' : width ? width : '800px'};
    text-align: ${({ isSmallScreen, textAlign }) => isSmallScreen ? 'left' : textAlign ? textAlign : 'center'};
    margin: ${({ isSmallScreen, margin }) => margin ? margin : isSmallScreen ? '0 20px' : '0 80px'};
    padding-top: ${({ paddingTop }) => paddingTop ? paddingTop : '0'};
`;


const Title = styled.h1`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '20px' : '22px'};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray3};

    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '8px' : '9px'};
`;

const SubCopy = styled.p`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '14px' : '16px'};
    color: ${({ theme, isSmallScreen }) => isSmallScreen ? theme.colors.gray5 : theme.colors.gray3};

    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '27px' : '40px'};
    letter-spacing: -0.24px;
`;

const ImgBox = styled.div`
    width: ${({ width }) => width ? width : '90%'};
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
`;