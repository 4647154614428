import { useMediaQuery } from 'react-responsive';

export default function useScreenSize() {
    const isSmallScreen = useMediaQuery({ maxWidth: 576 })
    const isMediumScreen = useMediaQuery({ minWidth: 577 });
    const isLargeScreen = useMediaQuery({ minWidth: 1280 });
    const isXLargeScreen = useMediaQuery({ minWidth: 1921 });

    return { isSmallScreen, isMediumScreen, isLargeScreen, isXLargeScreen };
}
