import { useRef, useState } from "react";
import useScreenSize from "../../hooks/useScreenSize";
import { theme } from "../../utils/palette";
import Content from "../common/Content";
import { membersData } from "../../datas/membersData";
import MemberCard from "./MemberCard";
import styled from "styled-components";
import useScrollVisible from "../../hooks/useScrollVisible";
import { OpacityAnimation, RefloatAnimation } from "../../styled/common";

const Members = () => {

    const { isSmallScreen } = useScreenSize();
    const [ memebers ] = useState(membersData);
    const [ isActive, setIsActive ] = useState(false);

    const handleCardActive = (active) => {
        setIsActive(active);
    };


    return (
        <section>
            <Content 
                title={'OUR MEMBERS'}
                color={theme.colors.gray4}
                isSmallScreen={isSmallScreen}
            />
            <ScrollableSection className="w-screen overflow-auto">
                <MemberList isSmallScreen={isSmallScreen}>
                {
                    memebers.map((member, index) => 
                        <MemberCard 
                            member={member}
                            isSmallScreen={isSmallScreen}
                            onActive={handleCardActive}
                            key={index}
                        />
                    )
                }
                </MemberList>
            </ScrollableSection>
        </section>
    );

}

export default Members;


const ScrollableSection = styled.div`
    &::-webkit-scrollbar {
        display: none;
    }
`;

const MemberList = styled.section`
    width: ${({ isSmallScreen }) => isSmallScreen ? '1072px' : '3355px'};
    padding: ${({ isSmallScreen }) => isSmallScreen ? '0 20px' : '30px 50px'};

    display: flex;
    flex-wrap: wrap;
    column-gap: ${({ isSmallScreen }) => isSmallScreen ? '8px' : '28px'};
    row-gap: ${({ isSmallScreen }) => isSmallScreen ? '24px' : ''};
    overflow-y: hidden;
    
    &::-webkit-scrollbar {
        display: none;
    }
`;