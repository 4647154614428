import { Directions, Inquiry } from "../components/InquiryPage";


const InquiryPage = ({ headerHeight }) => {

    return (
        <div style={{marginTop : headerHeight}}>
            <Inquiry />
            <Directions />
        </div>
    );

}

export default InquiryPage;