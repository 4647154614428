export const translateXValue = (startScroll, endScroll, scrollPosition) => {

    let translateValue = 0;
    const scrollRange = endScroll - startScroll;

    if (scrollPosition >= startScroll && scrollPosition <= endScroll) {
        const progress = (scrollPosition - startScroll) / scrollRange;
        translateValue = progress * 100;
    } else if (scrollPosition > endScroll) {
        translateValue = 100;
    }
    
    return translateValue;
}

export const scrollToSection = (sectionRef, headerHeight) => {
    if (sectionRef.current) {
        const sectionTop = sectionRef.current.getBoundingClientRect().top + window.scrollY;

        window.scrollTo({
            top: sectionTop - headerHeight,
            behavior: 'smooth',
        });
    }
};
