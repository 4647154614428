import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Header, Footer, ScrollToTop } from './components/layout';
import { MainPage, SolutionPage, InfoPage, InquiryPage } from './routes';
import { ThemeProvider } from 'styled-components';
import { theme } from './utils/palette';
import useHeaderHeight from './hooks/useHeaderHeight';
import { useEffect } from 'react';
import axios from 'axios';


function App() {

  // 초기설정 조회, guestToken 발급 및 localStorage에 저장
  useEffect(() => {
    (async () => {
      const existingToken = localStorage.getItem("guestToken");
      
      // 이미 토큰이 있다면 요청을 보내지 않음
      if (existingToken) return;
      
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const url = `${baseUrl}/v1/application/config?platform=web`;
      const config = {
        headers: {
          "x-app-id": process.env.REACT_APP_ID,
          "x-app-api-key": process.env.REACT_APP_API_KEY,
        },
      };
      
      try {
        const response = await axios.get(url, config);
        const guestToken = response.data.data.guestToken;
        localStorage.setItem("guestToken", guestToken);
      } catch (error) {
        console.error("Error fetching guestToken:", error);
      }
    })(); 
  }, []);

  const { height, headerHeight, headerRef } = useHeaderHeight();


  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <ScrollToTop />
          <Header ref={headerRef} />

          <Routes>
            <Route path='/' element={<MainPage height={height} headerHeight={headerHeight} />} />
            <Route path='/solution' element={<SolutionPage height={height} headerHeight={headerHeight} />} />
            <Route path='/info' element={<InfoPage height={height} headerHeight={headerHeight} />} />
            <Route path='/inquiry' element={<InquiryPage height={height} headerHeight={headerHeight} />} />
          </Routes>

          <Footer />
        </Router>
      </div>
    </ThemeProvider>
  );

}

export default App;
