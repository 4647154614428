import styled from "styled-components";
import mail from '../../img/icon/mail.svg';


const SubmitButton = ({ handleSubmit, isSmallScreen }) => {

    return (
        <div className={`mt-24 self-end`}>
            <Button type="submit" onClick={handleSubmit} isSmallScreen={isSmallScreen}>
                <img src={mail} className="relative top-px block w-24 h-24" />
                <span className="h-24">문의하기</span>
            </Button>
        </div>
    );

}

export default SubmitButton;


const Button = styled.button`
    width: ${({ isSmallScreen }) => isSmallScreen ? '320px' : '580px'};
    height: ${({ isSmallScreen }) => isSmallScreen ? '48px' : '64px'};
    background-color: ${({ theme }) => theme.colors.main1};
    padding: ${({ isSmallScreen }) => isSmallScreen ? '12px 36px 12px 32px' : '20px 36px 20px 32px'};
    border-radius: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '12px'};

    font-size: 18px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};

    display: flex;
    justify-content: center;
    gap: 12px;

    &:hover {
        background-color: ${({ theme }) => theme.colors.main1};
        opacity: 0.9;
    }
`;