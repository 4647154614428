import { useState } from "react";
import styled from "styled-components";

const MemberCard = ({ isSmallScreen, member }) => {

    const [ isFlipped, setIsFlipped ] = useState(false);
    const [ mousePos, setMousePos ] = useState({ x: 0, y: 0 });
    const [ isHovering, setIsHovering ] = useState(false);

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };
    
    const handleMouseMove = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const adjustedX = isFlipped ? rect.width - x : x;

        setMousePos({ x: adjustedX, y });
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    }


    return (
        <Card
            isFlipped={isFlipped}
            onClick={handleFlip}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            isSmallScreen={isSmallScreen}
        >
            <Overlay 
                isSmallScreen={isSmallScreen} 
                mousePos={mousePos} 
                isHovering={isHovering} />
            <Front memberImg={member.img} isSmallScreen={isSmallScreen}>
                <Info isSmallScreen={isSmallScreen}>
                    <NickName isSmallScreen={isSmallScreen}>
                        {member.nickname}
                    </NickName>
                    <Name isSmallScreen={isSmallScreen}>
                        <KrName isSmallScreen={isSmallScreen}>
                            {member.krName}
                        </KrName>
                        <EgName isSmallScreen={isSmallScreen}>
                            {member.egName}
                        </EgName>
                    </Name>
                    <Position isSmallScreen={isSmallScreen}>
                        <div>{member.headquarters}</div>
                        <Bar isSmallScreen={isSmallScreen} />
                        <div>{member.position}</div>
                    </Position>
                </Info>
            </Front>
            <Back isSmallScreen={isSmallScreen} bgColor={member.clickedBg}>
                <Rolling>
                    <Symbol className={`${isSmallScreen ? member.symbol.size.m : member.symbol.size.pc} mx-auto`}>
                        <img src={member.symbol.img} className="block w-full h-full" alt="symbol" />
                    </Symbol>
                    <Features isSmallScreen={isSmallScreen}>
                        <div className={`${isSmallScreen ? 'text-16' : 'text-24'} font-bold`}>
                            소금광산의
                        </div>
                        {
                            member.rolling.map((feature, index) => 
                                <div className={`${isSmallScreen ? 'text-12' : 'text-15'} font-medium`} key={index}>
                                    {feature}
                                </div>
                            )
                        }
                    </Features>
                </Rolling>
            </Back>
        </Card>
    );
};

export default MemberCard;


const Card = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '200px' : '300px'};
    height: ${({ isSmallScreen }) => isSmallScreen ? '300px' : '455px'};
    border-radius: ${({ isSmallScreen }) => isSmallScreen ? '200px' : '350px'};
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s;
    transform: ${({ isFlipped }) => isFlipped ? 'rotateY(180deg) scale(1)' : 'rotateY(0deg) scale(1)'};
    cursor: pointer;

    &:hover {
        transform: ${({ isSmallScreen, isFlipped }) => isSmallScreen ? '' : 
                    isFlipped ? 'rotateY(180deg) scale(1.05)' : 'rotateY(0deg) scale(1.05)'};
    }

    &:active {
        transform: ${({ isFlipped }) => isFlipped ? 'rotateY(180deg) scale(0.9)' : 'rotateY(0deg) scale(0.9)'};
    }
`;

const Overlay = styled.div`
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    pointer-events: none;
    border-radius: inherit;
    background: ${({ mousePos, isHovering }) =>
        isHovering
            ? `radial-gradient(circle at ${mousePos.x}px ${mousePos.y}px, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1) 80%)`
            : 'none'};
    opacity: ${({ isHovering }) => (isHovering ? 1 : 0)};
    transition: opacity 0.1s ease, background 0.1s ease, transform 0.6s ease;
`;

const Front = styled.div`
    width: inherit;
    height: inherit;
    border-radius: ${({ isSmallScreen }) => isSmallScreen ? '200px' : '350px'};
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 58.87%, rgba(0, 0, 0, 0.16) 67.09%, rgba(0, 0, 0, 0.80) 100%),
                url(${({ memberImg }) => memberImg}) lightgray 50% / cover no-repeat;
    position: absolute;
    display: flex;
    justify-content: center;
    backface-visibility: hidden;
`;

const Back = styled.div`
    width: inherit;
    height: inherit;
    border-radius: ${({ isSmallScreen }) => isSmallScreen ? '200px' : '350px'};
    background-color: ${({ bgColor }) => bgColor};
    position: absolute;
    top: 0;
    transform: rotateY(180deg);
    backface-visibility: hidden;
`;

const Info = styled.div`
    height: ${({ isSmallScreen }) => isSmallScreen ? '45px' : '75px'};
    margin: 0 auto;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: ${({ isSmallScreen }) => isSmallScreen ? '207px' : '310px'};
`;

const NickName = styled.div`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '16px'};
    font-weight: 500;
`;

const Name = styled.div`
    display: flex;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '2.8px' : '5px'};
`;

const KrName = styled.span`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '14px' : '22px'};
    font-weight: 700;
`;

const EgName = styled.span`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '14.5px' : '24px'};
    font-weight: 700;
`;

const Position = styled.div`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '14px'};
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '4px' : '8px'};
`;

const Bar = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '1px' : '1.5px'};
    height: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '17px'};
    background-color: ${({ theme }) => theme.colors.white};
`;

const Rolling = styled.div`
    line-height: normal;
`;

const Symbol = styled.div``;

const Features = styled.div`
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '4px' : '8px'};
`;