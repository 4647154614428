import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import { useMediaQuery } from "react-responsive";
import { partnerLogo } from "../../datas/data.js";
import { theme } from "../../utils/palette.js";

const PartnerCards = ({ isSmallScreen }) => {
    const isOverFlow = useMediaQuery({ minWidth: 1580 });
    
    const [firstLine, setFirstLine] = useState([...partnerLogo[0]]);
    const [secondLine, setSecondLine] = useState([...partnerLogo[1]]);
    
    // useEffect(() => {
    //     if (isOverFlow) {
    //         setFirstLine([...partnerLogo[0]]);
    //         setSecondLine([...partnerLogo[1]]);
    //     } else {
    //         setFirstLine([...partnerLogo[0]]);
    //         setSecondLine([...partnerLogo[1]]);
    //     }
    // }, [isOverFlow]);

    const bgColor = theme.colors.gray1;

    return (
        <Wrapper isOverFlow={isOverFlow} isSmallScreen={isSmallScreen}>
            <SlideWrapper>
                <SlideLeft isOverFlow={isOverFlow}>
                {
                    firstLine.map((partner, i) => (
                        <Card bgColor={bgColor} isSmallScreen={isSmallScreen} key={i}>
                            <PartnerImage src={partner} bgColor={bgColor} />
                        </Card>
                    ))
                }
                {   isOverFlow || (
                    firstLine.map((partner, i) => (
                        <Card bgColor={bgColor} isSmallScreen={isSmallScreen} key={`clone-${i}`}>
                            <PartnerImage src={partner} bgColor={bgColor} />
                        </Card>
                    )))
                }
                </SlideLeft>
            </SlideWrapper>
            <SlideWrapper>
                <SlideRight isOverFlow={isOverFlow}>
                {
                    secondLine.map((partner, i) => (
                        <Card bgColor={bgColor} isSmallScreen={isSmallScreen} key={i}>
                            <PartnerImage src={partner} bgColor={bgColor} />
                        </Card>
                    ))
                }
                {   isOverFlow || (
                    secondLine.map((partner, i) => (
                        <Card bgColor={bgColor} isSmallScreen={isSmallScreen} key={`clone-${i}`}>
                            <PartnerImage src={partner} bgColor={bgColor} />
                        </Card>
                    )))
                }
                </SlideRight>
            </SlideWrapper>
        </Wrapper>
    );
}

export default PartnerCards;


const Wrapper = styled.div`
    width: ${({ isOverFlow }) => isOverFlow ? '1580px' : '100%'};
    margin: 0 auto;
    overflow: hidden;
    
    display: flex;
    flex-direction: column;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '4px' : '19px'};
`;

const SlideWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
`;

const slideLeft = keyframes`
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-50%);
    }
`;

const slideRight = keyframes`
    0% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(0%);
    }
`;

const SlideLeft = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    animation: ${({ isOverFlow }) => !isOverFlow && css`
        ${slideLeft} 15s linear infinite;
    `};
`;

const SlideRight = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    animation: ${({ isOverFlow }) => !isOverFlow && css`
        ${slideRight} 15s linear infinite;
    `};
`;

const Card = styled.div`
    width: ${ ({isSmallScreen}) => isSmallScreen ? '70px' : '180px' };
    height: ${ ({isSmallScreen}) => isSmallScreen ? '30px' : '80px' };
    background-color: ${({ bgColor }) => bgColor};
    margin-right: ${ ({isSmallScreen}) => isSmallScreen ? '4px' : '20px' };

    border-radius: ${ ({isSmallScreen}) => isSmallScreen ? '3.75px' : '10px' };

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    z-index: 2;

    overflow: hidden;
`;

const PartnerImage = styled.img`
    display: block;
    max-width: 80%;
    max-height: 80%;
    background-color: ${({ bgColor }) => bgColor};
    object-fit: contain;
`;
