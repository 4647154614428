import styled from "styled-components";


const Content = ({ title, subCopy, color, isSmallScreen }) => {

    return (
        <div>
            <Title 
                dangerouslySetInnerHTML={{ __html: title }} 
                color={color}
                isSmallScreen={isSmallScreen}

            />
            <SubCopy 
                dangerouslySetInnerHTML={{ __html: subCopy }} 
                color={color}
                isSmallScreen={isSmallScreen}
            />
        </div>
    );

}

export default Content;


const Title = styled.h1`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '16px' : '24px' };
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray4 };
    text-align: center;
    lineHeight: 140%;

    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '8px' : '16px' };
`;

const SubCopy = styled.p`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '14px' : '18px' };
    color: ${({ theme }) => theme.colors.gray3 };
    text-align: center;
    line-height: 160%;
    letter-spacing: -0.27px;

    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '20px' : '36px' };
`;