import styled from "styled-components";


const Card = ({ isSmallScreen, pressRelease }) => {

    const handleOpenLink = () => {
        if (pressRelease.link) {
            window.open(pressRelease.link, '_blank');
        }
    };


    return (
        <CardContainer isSmallScreen={isSmallScreen}>
            <div onClick={handleOpenLink} className={`${isSmallScreen ? 'w-180' : 'w-360'} cursor-pointer`}>
                <Thumbnail isSmallScreen={isSmallScreen}>
                    <img src={pressRelease.img} />
                </Thumbnail>
                <Head isSmallScreen={isSmallScreen}>
                    { pressRelease.head }
                </Head>
            </div>
            <Date isSmallScreen={isSmallScreen}>
                { pressRelease.date }
            </Date>
        </CardContainer>
    );

}

export default Card;


const CardContainer = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '190px' : '380px'};
    height: ${({ isSmallScreen }) => isSmallScreen ? '190px' : '380px'};
    background-color: ${({ theme }) => theme.colors.white};
    padding: ${({ isSmallScreen }) => isSmallScreen ? '5px 5px 8px 5px' : '10px 10px 16px 10px'};

    border-radius: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '20px'};
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.10);

    position: relative;
`;

const Thumbnail = styled.div`
    width: 100%;
    height: ${({ isSmallScreen }) => isSmallScreen ? '120px' : '240px'};
    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '4px' : '8px'};

    border: 1px solid ${({ theme }) => theme.colors.gray6};
    border-radius: ${({ isSmallScreen }) => isSmallScreen ? '8px' : '16px'};

    img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: ${({ isSmallScreen }) => isSmallScreen ? '8px' : '16px'};
    }
`;

const Head = styled.h1`
    height: auto;
    padding: ${({ isSmallScreen }) => isSmallScreen ? '0 4px' : '0 8px'};
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '12px' : '18px'};
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray3};
    line-height: 140%;
    white-space: normal;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

const Date = styled.div`
    padding: ${({ isSmallScreen }) => isSmallScreen ? '0 4px' : '0 8px'};
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '14px'};
    color: #7E7E7E;
    line-height: 140%;

    position: absolute;
    bottom: ${({ isSmallScreen }) => isSmallScreen ? '5px' : '8px'};
`;