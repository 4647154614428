export const theme = {
    
  colors: {
    main1: '#0066FF',
    main2: '#003399',
    main3: '#E6F0FF',
    accent1: '#B0D0FF',
    accent2: '#D9E8FF',
    accent3: '#0190CC',
    accent4: '#FF5B19',
    white: '#FFFFFF',
    gray1: '#F5F5F5',
    gray2: '#5C5C5C',
    gray3: '#3C3C3C',
    gray4: '#2F2F2F',
    gray5: '#4F4F4F',
    gray6: '#EBEBEB',
    gray7: '#D3D3D3',
    gray8: '#7E7E7E',
    black: '#000000'
  }
    
};