import styled, { css } from 'styled-components';
import salti from '../../img/SALTi.svg';
import vectorBlue from '../../img/vector_blue.png';
import vectorNavy from '../../img/vector_navy.png';
import arrowShort from '../../img/arrow/arrow_short.svg';
import arrowBentRight from '../../img/arrow/arrow_bent_right.svg';
import arrowBentBottom from '../../img/arrow/arrow_bent_bottom.svg';
import { RefloatAnimation, OpacityAnimation, Section } from '../../styled/common.js';
import { theme } from '../../utils/palette.js';
import { krMain } from '../../datas/data.js';
import Content from '../common/Content.js';
import useScreenSize from '../../hooks/useScreenSize.js';


const ProductUseSolution = ({ height }) => {

    const { isSmallScreen } = useScreenSize();

    const title = isSmallScreen ? krMain.solution.title.m : krMain.solution.title.pc;
    const subCopy = isSmallScreen ? krMain.solution.subCopy.m : krMain.solution.subCopy.pc;
    const color = theme.gray3;
    const sectionHeight = isSmallScreen ? height : '650px';
    

    return (
        <Section className="bg-gray1" height={sectionHeight}>
            <section className={`${isSmallScreen ? 'w-330' : 'w-780'} mx-auto`}>
                <Content
                    title={title}
                    subCopy={subCopy}
                    color={color}
                    isSmallScreen={isSmallScreen}
                />
                {/* 이미지 영역 */}
                <div 
                    className={`relative flex bg-accent2 ${isSmallScreen ? 'rounded-17 py-10 pl-18 pr-11 gap-x-18' : 'rounded-40 py-24 pl-43 pr-24 gap-x-42'}`}
                >
                    <div className={`${isSmallScreen ? 'w-38 h-12' : 'w-97 h-27'} self-center`}>
                        <img src={salti} class="block w-full h-full"/>
                    </div>
                    <div className={`relative flex flex-col ${isSmallScreen ? 'gap-y-8' : 'gap-y-24'}`}>
                        {/* 첫번째 하얀 타원 */}
                        <CircleContainer isSmallScreen={isSmallScreen}>
                            <Circle 
                                className={`bg-main1 ${isSmallScreen ? 'w-30 h-42 px-6 ml-4' : 'w-68 h-100 px-16 ml-10'}`} 
                                isSmallScreen={isSmallScreen}
                                
                            >
                                데이터 요청
                            </Circle>
                            <div 
                                className={`relative ${isSmallScreen ? 'w-88 w-42 ml-55' : 'w-210 h-100 ml-127'}`} 
                                
                            >
                                <Img src={vectorBlue} />
                                <TextOnImage 
                                    style={{
                                        top : isSmallScreen ? '17px' : '40px', 
                                        left : isSmallScreen ? '6.75px' : '16px'
                                    }} 
                                    isSmallScreen={isSmallScreen}
                                    
                                >
                                    데이터 분석
                                </TextOnImage>
                                <TextOnImage 
                                    style={{
                                        top : isSmallScreen ? '13.08px' : '31px', 
                                        right : isSmallScreen ? '4.55px' : '12px'
                                    }} 
                                    isSmallScreen={isSmallScreen}
                                    
                                >
                                    맞춤형 상품<br/>
                                    &서비스 추천
                                </TextOnImage>
                            </div>
                        </CircleContainer>
                        {/* 두번째 하얀 타원 */}
                        <CircleContainer isSmallScreen={isSmallScreen}>
                            <div className={`relative ${isSmallScreen ? 'w-88 h-42 ml-4' : 'w-210 h-100 ml-10'}`}>
                                <Img src={vectorNavy}  />
                                <TextOnImage 
                                    style={{
                                        top : isSmallScreen ? '17px' : '40px', 
                                        left : isSmallScreen ? '6.75px' : '16px'
                                    }} 
                                    isSmallScreen={isSmallScreen}
                                    
                                >
                                    데이터 도출
                                </TextOnImage>
                                <TextOnImage 
                                    style={{
                                        top : isSmallScreen ? '17px' : '40px', 
                                        right : isSmallScreen ? '6.44px' : '16px'
                                    }} 
                                    isSmallScreen={isSmallScreen}
                                    
                                >
                                    데이터 제공
                                </TextOnImage>
                            </div>
                            <Circle 
                                className={`bg-main2 ${isSmallScreen ? 'w-30 h-16 pl-6 pr-6 pt-14 pb-12 ml-105 mr-4' : 'w-73 h-38 pl-14 pr-13 pt-33 pb-29 ml-250 mr-10'}`}
                                isSmallScreen={isSmallScreen}
                                
                            >
                                상품&서비스<br/>
                                이용
                            </Circle>
                        </CircleContainer>
                    </div>
                    <Chip 
                        className="absolute" 
                        style={{
                            top : isSmallScreen ? '9px' : '24px', 
                            left : isSmallScreen ? '70px' : '176px'
                        }} 
                        isSmallScreen={isSmallScreen}
                        
                    >
                        기업
                    </Chip>
                    <Chip 
                        className="absolute" 
                        style={{
                            top : isSmallScreen ? '69.88px' : '168px', 
                            left : isSmallScreen ? '70px' : '176px'
                        }} 
                        isSmallScreen={isSmallScreen}
                        
                    >
                        고객
                    </Chip>
                    {/* 화살표 */}
                    <div 
                        className={`absolute ${isSmallScreen ? 'h-14' : 'h-36'}`} 
                        style={{
                            top : isSmallScreen ? '57.15px' : '138px', 
                            left : isSmallScreen ? '96px' : '227px'
                        }}
                    >
                        <Img src={arrowShort} />
                    </div>
                    <div 
                        className={`absolute ${isSmallScreen ? 'w-28 h-38' : 'w-67 h-99'}`}  
                        style={{
                            top : isSmallScreen ? '33px' : '76px', 
                            left : isSmallScreen ? '145px' : '343px'
                        }}
                    >
                        <Img src={arrowBentRight} />
                    </div>
                    <div 
                        className={`absolute ${isSmallScreen ? 'w-29 h-37' : 'w-75 h-91'}`}   
                        style={{
                            top : isSmallScreen ? '35px' : '83px', 
                            right : isSmallScreen ? '35.37px' : '78px'
                        }}
                    >
                        <Img src={arrowBentBottom} />
                    </div>
                </div>
            </section>
        </Section>
    );

}

export default ProductUseSolution;


const visibilityStyles = css`
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
    transition: all 0.8s ease;
`;

const Img = styled.img`
    display: block;
    width: 100%;
    height: 100%;
`;

const CircleContainer = styled.div`
    width: ${ ({isSmallScreen}) => isSmallScreen ? '245px' : '580px' };
    height: ${ ({isSmallScreen}) => isSmallScreen ? '50.6px' : '120px' };
    background-color: ${ ({theme}) => theme.colors.white };
    
    border-radius: ${ ({isSmallScreen}) => isSmallScreen ? '25.3px' : '60px' };

    display: flex;
    align-items: center;
`;

const Circle = styled.div`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '6.328px' : '15px' };
    color: #FFFFFF;
    text-align: center;
    line-height: 130%; /* 19.5px */
    letter-spacing: -0.225px;

    border-radius: 80px;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    box-sizing: content-box;
`;

const TextOnImage = styled.span`
    display: inline-block;
    position: absolute;

    font-size: ${({ isSmallScreen }) => isSmallScreen ? '6.328px' : '15px' };
    color: #FFFFFF;
    text-align: center;
    line-height: 130%; /* 19.5px */
    letter-spacing: -0.225px;
`;

const Chip = styled.span`
    background-color: #FFFFFF;

    font-size: ${({ isSmallScreen }) => isSmallScreen ? '8px' : '16px' };
    font-weight: 600;
    color:${ ({theme}) => theme.colors.gray4};
    line-height: ${({ isSmallScreen }) => isSmallScreen ? '12.656px' : '30px;' }
    letter-spacing: -0.24px;

    border: 1px solid #0066FF;
    border-radius: 30px;

    display: inline-flex;
    padding: ${({ isSmallScreen }) => isSmallScreen ? '1px 5.06px 0px 4.94px' : '0px 11px 0px 10px' };
    justify-content: center;
    align-items: center;
`;